import React from 'react';
import Slider from 'react-slick';

import BS from 'assets/images/bs-desktop.png';
import BSWebp from 'assets/images/bs-desktop.png?as=webp';
import BSMobile from 'assets/images/bs-mobile.png';
import BSMobileWebp from 'assets/images/bs-mobile.png?as=webp';

import loadable from '@loadable/component';

const ImageLoader = loadable(() => import('./common/ImageLoader'));

/**
 * @returns {JSX.Element}
 * @constructor
 */

const contentItem = {
  baseColor: '#FFC140',
  mainHeading: () => (
    <>
     Clear the air with <strong>Mila</strong>
    </>
  ),
  title: 'The award-winning air purifier for every season.',
  description: () => (<>Get ahead of allergy season with the filter designed specifically to filter out the particulates that make you a-a-achoo.</>),
  descriptionMobile: () => (
    <>
     Get ahead of allergy season with <br /> the filter designed specifically to <br /> filter out the particulates that <br />make you a-a-achoo.
    </>
  ),
  image: {
    desktop: {
      defaultUrl: BS,
      webpUrl: BSWebp,
    },
    mobile: {
      defaultUrl: BSMobile,
      webpUrl: BSMobileWebp,
    },
    alt: 'The mother of all filters.',
  },
};

const ContentItem = ({
  baseColor, mainHeading, title, description, descriptionMobile, image,
}) => (
  <div className='hero hero--feb2024 hero--home-center hero--small'>
    <div className='hero__holder'>
      <div className='hero__header'>
        <h1 className='hero__title hero__title--small' style={{ '--color': `${baseColor}` }}>{mainHeading()}</h1>
      </div>
      <ImageLoader
        className='hero__image hero__image--2024fab img-relative'
        placeholderAspectRatio={834 / 678}
        image={image.desktop.defaultUrl}
        webp={image.desktop.webpUrl}
        smallImage={image.mobile.defaultUrl}
        smallWebp={image.mobile.webpUrl}
        alt={image.alt}
      />
      <div className='hero__content'>
        <h2 className='hero__subtitle hero__subtitle--alt'>
          {title}
        </h2>
        <div className='hero__description hero__description--small'>
          <p className='for-desktop'>
            {description()}
          </p>
          <p className='for-mobile'>{descriptionMobile()}</p>
        </div>
      </div>

    </div>
  </div>
);

const HeroMarch2024Allergies = () => {
  const awardSettings = {
    autoplay: true,
    speed: 600,
    cssEase: 'cubic-bezier(0.65,0.05,0.36,1)',
    slidesToShow: 4,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    pauseOnFocus: false,
    arrows: false,
    responsive: [
      {
        mobileFirst: true,
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          mobileFirst: true,
        },
      },
    ],
  };

  return (
    <section className='heroFlex'>
      <div className='heroFlex__card'>
        <div className='awards awards--four'>
          <div className='container'>
            <Slider {...awardSettings} className='awards__slider'>
              <blockquote className='awards__item'>
                <div className='awards__itemInner'>
                  <h2>
                    ‘Best
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Popular Science</cite>
                </div>
              </blockquote>
              <blockquote className='awards__item'>
                <div className='awards__itemInner'>
                  <h2>
                    ‘A Truly Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>NYTimes/Wirecutter</cite>
                </div>
              </blockquote>
              <blockquote className='awards__item'>
                <div className='awards__itemInner'>
                  <h2>
                    ‘Best  Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Homes & Gardens</cite>
                </div>
              </blockquote>
              <blockquote className='awards__item'>
                <div className='awards__itemInner'>
                  <h2>
                    ‘Best  Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Gear Patrol</cite>
                </div>
              </blockquote>
            </Slider>
          </div>
        </div>
        <ContentItem {...contentItem} />
      </div>
    </section>
  );
};

export default HeroMarch2024Allergies;
